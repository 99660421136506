import React, { useEffect } from "react";
import { AgoraEduSDK } from "agora-classroom-sdk";
import {
  AgoraPolling,
  AgoraSelector,
  AgoraCountdown,
  FcrBoardWidget,
  FcrWebviewWidget,
  AgoraHXChatWidget,
  FcrWatermarkWidget,
  FcrStreamMediaPlayerWidget,
} from "agora-plugin-gallery/classroom";

const Classroom = ({ data }) => {
  useEffect(() => {
    AgoraEduSDK.config({
      appId: process.env.REACT_APP_AGORA_APP_ID,
      region: "NA",
    });

    AgoraEduSDK.launch(document.querySelector("#root"), {
      userUuid: data.uid.toString(),
      userName: data.user_name,
      roomUuid: data.room_uid,
      roleType: data.role_type, // User roles: 1 is teacher, 2 is student.
      roomType: data.room_type, // Room type: 0 is one-on-one, 2 is large class, and 4 is small class.
      roomName: data.room_name,
      rtmToken: data.token, // In a test environment, you can use a temporary RTM Token; in a production or security environment, it is strongly recommended that you use a server-generated RTM Token.
      language: data.language, // The language of the classroom interface. If the interface is in English, set it to 'en'.
      duration: data.duration, // Course time in seconds.
      recordUrl: data.record_url,
      pretest: true, // Whether to enable pre-class equipment detection.
      courseWareList: [],
      virtualBackgroundImages: ['./assets/01.webp'], // Virtual background image resource list.
      webrtcExtensionBaseUrl: "https://solutions-apaas.agora.io/static", // WebRTC Plug-in deployment address.
      uiMode: "light", // Set the classroom interface to bright mode. If you want the interface to be in dark mode, set it to 'dark'.
      widgets: {
        popupQuiz: AgoraSelector,
        countdownTimer: AgoraCountdown,
        easemobIM: AgoraHXChatWidget,
        mediaPlayer: FcrStreamMediaPlayerWidget,
        poll: AgoraPolling,
        watermark: FcrWatermarkWidget,
        webView: FcrWebviewWidget,
        netlessBoard: FcrBoardWidget,
      },
      listener: (evt, args) => {},
    });
  }, []);

  return (
    <div
      id="agora_classroom_container"
      style={{ width: "100%", height: "100vh" }}
    />
  );
};

export default Classroom;
