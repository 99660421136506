import "../App.css";
import axios from "axios";
import Classroom from "./Classroom";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";

function App() {
  const { channelName } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL + "agora/rtm-token",
          { channel_name: channelName }
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [channelName]);

  return data ? (
    <div className="App">
      <Classroom data={data} />
    </div>
  ) : (
    <>Loading...</>
  );
}

export default App;
